import { Component } from '@angular/core';
import { FeedbackService, Logger, ToastService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { ToastType } from '@shared/enums';
import { Feedback } from '@shared/model';

@Component({
  selector: 'unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  constructor(
    private feedbackService: FeedbackService,
    private toastService: ToastService,
    private translate: TranslateService,
    private logger: Logger,
  ) {}

  public async requestAccess() {
    const requestAccess: Feedback = {
      rating: 5,
      reason: 'Request Access for DeviceManifest',
      description: 'Can you grant me the necessary SICK firmware developer permissions?',
      anonymous: false,
      url: window.location.href,
    };
    try {
      await this.feedbackService.sendFeedback(requestAccess);
      this.toastService.create({
        title: this.translate.instant('unauthorized.toast.header'),
        message: this.translate.instant('unauthorized.toast.message'),
        type: ToastType.SUCCESS,
      });
    } catch (err) {
      this.logger.error('SEND_REQUEST_ACCESS_ERROR', requestAccess, err);
    }
  }
}
