import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, User } from '@core/services';
import { UserRole } from '@shared/enums';

@Injectable()
export class FirmwareDeveloperGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser: User | undefined = this.authService.currentUser();
    if (currentUser?.hasRole(UserRole.MANIFEST_DEVELOPER)) {
      return true;
    }
    this.router.navigate(['/unauthorized']);
    return false;
  }
}
