import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ManifestService } from '@core/services';
import { AddDeviceDialog } from '@shared/dialogs';
import { Device, ManifestDetail } from '@shared/model';

@Component({
  selector: 'devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent {
  @Input() manifest: ManifestDetail;
  @Input() editMode: boolean;

  private dialogRefAddDevice: MatDialogRef<AddDeviceDialog>;

  constructor(private dialog: MatDialog, private manifestService: ManifestService) {}

  public openAddDeviceDialog(device?: any) {
    this.dialogRefAddDevice = this.dialog.open(AddDeviceDialog, {
      data: { manifest: this.manifest.uuid, device: device },
    });
    this.dialogRefAddDevice.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.manifestService.getAllManifests();
        this.manifest.devices.push(result);
      }
    });
  }

  public removeDevice(deviceToDelete: Device) {
    this.manifest.devices = this.manifest.devices.filter((device) => device.partNumber !== deviceToDelete.partNumber);
  }
}
