import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IntegrationLayerService, Logger, ResponsiveService, ToastService } from '@core/services';
import { ManifestService } from '@core/services/manifest-service/manifest.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastType } from '@shared/enums';
import { Device, Manifest, ProductListItem } from '@shared/model';
import { RowData } from '@sick-davinci/grid-elements';

@Component({
  selector: 'manifest-list',
  templateUrl: './manifest-list.component.html',
  styleUrls: ['./manifest-list.component.scss'],
})
export class ManifestListComponent {
  public isMobile: boolean;
  public isLoading: boolean = true;
  public foundManifests: Manifest[] = [];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private manifestService: ManifestService,
    private integrationLayerService: IntegrationLayerService,
    private logger: Logger,
    private responsiveService: ResponsiveService,
    private toastService: ToastService,
  ) {
    this.syncDeviceData();
    this.responsiveService.screenWidthChanged().subscribe((isMobile) => (this.isMobile = isMobile));
    this.manifestService.getAllManifests().subscribe((manifests) => {
      this.foundManifests = manifests;
      this.isLoading = false;
    });
  }

  public async open(manifest: Manifest) {
    this.router.navigate(['manifest/', manifest.uuid]);
  }

  public openManifestinEditMode(event: CustomEvent, manifest: Manifest) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['manifest/', manifest.uuid], { queryParams: { editMode: 'true' } });
  }

  public onNotFoundIn4PM(row: RowData) {
    this.toastService.create({
      title: `${this.translate.instant('manifest.warning.notFoundTitle')}`,
      message: `${this.translate.instant('manifest.warning.notFoundMessage', {
        deviceType: row.deviceType,
        partNumber: row.partNumber,
      })}`,
      type: ToastType.WARNING,
    });
  }

  private async syncDeviceData() {
    const productFamilies = await this.manifestService.getAllDeviceTypes();
    productFamilies.map((productFamily) =>
      productFamily.devices.map(async (device) => {
        let devicesResult4PM: ProductListItem[] = [];
        try {
          devicesResult4PM = await this.integrationLayerService.getProducts(device.partNumber);
        } catch (err) {
          this.logger.error('SEARCH_FOR_DEVICES_IN_4PM_DB', {}, err);
          this.toastService.create({
            title: `${this.translate.instant('warning.not-found.title')}`,
            message: `${this.translate.instant('warning.not-found.message', {
              deviceType: device.deviceType,
              partNumber: device.partNumber,
            })}`,
            type: ToastType.WARNING,
          });
        }

        const deviceFirstFoundEntryFrom4PM: ProductListItem = devicesResult4PM[0];
        if (deviceFirstFoundEntryFrom4PM && this.isDeviceDataChanged(device, deviceFirstFoundEntryFrom4PM)) {
          device.productFamily = <string>deviceFirstFoundEntryFrom4PM.ProductFamily;
          device.deviceType = deviceFirstFoundEntryFrom4PM.Name;

          try {
            await this.manifestService.updateDeviceType(device);
          } catch (err) {
            this.logger.error('UPDATE_DEVICE_FROM_MANIFEST_DB', {}, err);
            throw err;
          }
        }
      }),
    );
  }

  private isDeviceDataChanged(device: Device, deviceFrom4PM: ProductListItem): boolean {
    return (
      device.deviceType !== deviceFrom4PM.Name ||
      !!(deviceFrom4PM.ProductFamily && device.productFamily !== deviceFrom4PM.ProductFamily)
    );
  }
}
